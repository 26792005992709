const currentNavbarItem = document.querySelector("#navbar li a[aria-current=\"page\"]");

if (!currentNavbarItem) {
    const links = document.querySelectorAll("#navbar li a");

    let index = 0;

    for (let i = 0; i < links.length; i++) {
        const link = links[i];
        if (window.location.href.includes(link.href)) {
            index = i;
        }

        if (index !== 0) break;
    }

    if (links.length) {
        links[index].setAttribute("aria-current", "page");
    }
}

