const STARS_INTERVAL = 2000;
const SVG_PATH = "M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z";

function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function animateStar(star) {
    star.style.setProperty("--star-left", `${random(-10, 100)}%`);
    star.style.setProperty("--star-top", `${random(10, 70)}%`);

    star.style.animation = "none";
    star.offsetHeight;
    star.style.animation = "";
}

function initStars() {
    const star = document.createElement("div");
    star.classList.add("star");
    star.classList.add("hidden");

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("viewBox", "0 0 512 512");

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("d", SVG_PATH);

    svg.appendChild(path);
    star.appendChild(svg);

    const stars = document.querySelectorAll(".stars");
    stars.forEach(elem => {
        const initialContent = elem.innerHTML;
        elem.innerHTML = "";

        for (let i = 0; i < 3; i++) {
            elem.appendChild(star.cloneNode(true));
        }

        const text = document.createElement("span");
        text.innerHTML = initialContent;

        elem.appendChild(text);
    });
}

function startStarAnimations() {
    const starContainers = document.querySelectorAll(".stars");
    starContainers.forEach((stars) => {
        let index = 0; 
        for(const star of stars.querySelectorAll(".star")) {
            setTimeout(() => {
                star.classList.remove("hidden");
                animateStar(star);

                setInterval(() => animateStar(star), STARS_INTERVAL + random(0, 200));
            }, index++ * (STARS_INTERVAL / 3))
        }
    });
}

initStars(); 
startStarAnimations();

