import "../sass/index.scss";
import "./contact-dialog";
import "./navbar-items";
import "./mobile-menu";
import "./stars";

if (location.pathname === "/"){
    import("./timeline-dialog");
    import("./fancybox");
    import("./glide");

    const AOS = require('aos');
    import('aos/dist/aos.css');

   AOS.init({ mirror: false });
}

