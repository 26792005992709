const overlay = document.querySelector("#contact-overlay");
const dialog = document.querySelector("#contact-dialog");
const closeBtn = document.querySelector("#contact-close")


function toggleContact() {
    overlay.classList.toggle("visible")
}

if (dialog) dialog.addEventListener("click", e => { e.stopPropagation(); });
if (overlay) overlay.addEventListener("click", toggleContact);
if (closeBtn) closeBtn.addEventListener("click", toggleContact);

window.toggleContact = toggleContact;
